<template>
    <div>
        <!-- 1L-->
        <van-sticky>
            <van-nav-bar title="历史订单"/>
        </van-sticky>
        <!-- 2L-->
        <van-cell-group>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-cell v-for="item in list" :key="item.ID">
                        <van-cell :clickable="true" @click="toItem(item)">
                            <template #icon>
                                <van-icon>
                                    <svg class="icon" aria-hidden="true" font-size="100px">
                                        <use xlink:href="#imfyc-icon-dianchidianya"></use>
                                    </svg>
                                </van-icon>
                            </template>
                            <template #title>
                                订单号：{{ item.OrderNo }}
                                <van-tag type="success" v-if="item.States === 3">完成</van-tag>
                                <van-tag type="primary" v-else-if="item.States === 2">使用中</van-tag>
                                <van-tag type="warning" v-else>{{ item.OrderStatesName }}</van-tag>
                            </template>
                            <template #label>
                                设备号：{{ item.DeviceSN }}
                                <br>
                                支付时间：{{ item.CreateTime }}
                            </template>
                        </van-cell>
                    </van-cell>
                </van-list>
            </van-pull-refresh>
        </van-cell-group>
    </div>
</template>

<script>

    import {Cell, CellGroup, Icon, List, NavBar, PullRefresh, Sku, Sticky, Tag, Toast} from 'vant';
    import {handleZero, isEmpty} from "../../utils/common";

    export default {
        name: "order",
        components: {
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            [Cell.name]: Cell,
            [NavBar.name]: NavBar,
            [CellGroup.name]: CellGroup,
            [Tag.name]: Tag,
            [Icon.name]: Icon,
            [Sticky.name]: Sticky,
            [Sku.name]: Sku

        },
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                listLine: 5,
                listPage: 1,
                userId: null
            };
        }

        , mounted() {
            this.userId = this.$route.params.userId;
            if (isEmpty(this.userId)) {
                let tmp = JSON.parse(sessionStorage.getItem('USER'));
                if (isEmpty(tmp)) {
                    Toast.fail('无法获取当前用户');
                    return;
                }
                this.userId = tmp.ID;
            }
            //
            this.refreshing = true;
        }

        , methods: {
            onLoad() {
                //
                if (this.refreshing) {
                    this.list = [];
                    this.listPage = 1;
                    this.refreshing = false;
                }
                //
                this.getOrderPage();
            }
            //
            , getOrderPage() {
                let count = this.list.length;
                let order = {};
                order.UserID = isEmpty(this.userId) ? '2031' : this.userId;
                order.line = this.listLine;
                order.page = this.listPage++;
                this.$api.api.req(this.$api.order.getOrderPage(order)).then(resp => {
                    //
                    if (isEmpty(resp) || isEmpty(resp.data) || isEmpty(resp.data.data)) {
                        Toast.fail('订单获取失败');
                        return;
                    }
                    //
                    count = resp.data.count;
                    //
                    for (let i = 0; i <= resp.data.data.length - 1; i++) {
                        let tmp = resp.data.data[i];
                        tmp.DeviceSN = handleZero(tmp.DeviceSN);
                        this.list.push(tmp);
                    }
                    //
                    if (this.list.length >= count) {
                        this.finished = true;
                    }
                    //
                    this.loading = false;
                });
            }
            //
            , onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                this.loading = true;
                this.onLoad();
            }
            //
            , toItem(item) {
                sessionStorage.setItem('ORDER', JSON.stringify(item));
                this.$router.push({name: 'item'});
            }
        }
    }
</script>

<style scoped>

</style>
